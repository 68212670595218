@import 'src/style/variables';

.categorySelect {
  padding: 2px 25px !important;
  & span {
    transform: translateX(-6px);
  }
}

.treeSelect input[type="text"] {
  font-size: 14px;
  width: 100%;
}

.subHeaderButton {
  padding: 2px 0;
}

.input {
  height: 28px;
  box-shadow: 0 0 0 2px #dbdfea;
  background-color: $white;
  max-width: 120px;
  font-size: 14px;

  &.active {
    box-shadow: 0 0 0 2px $secondary;
  }
}

.triangle {
  height: 24px;
  width: 24px;
  z-index: 1;

  transform: translate(35px, 50%) rotate(45deg);

  @media screen and (max-width: 1535px) {
    transform: translate(390px, 50%) rotate(45deg);
  }
}
//.trianglePositionLeft {
//  transform: translate(35px, 50%) rotate(45deg);
//}
//
//.trianglePositionRight {
//  transform: translate(390px, 50%) rotate(45deg);
//}

.saveWindow {
  width: 450px;
  transform: translateX(10px);
  box-shadow: 0 8px 10px rgba(81, 90, 105, 0.14),
  0 3px 14px rgba(81, 90, 105, 0.12);
  border-radius: $borderRadius;
}

.btn {
  font-size: 14px;
  padding: 5px 24px;

  &:last-of-type {
    margin-left: 16px;
  }
}

.dateWrapper {
  max-height: initial;
}

.datePicker {
  font-size: 11px;
}

.valuationItem {
  user-select: inherit;
  border-bottom: 1px solid transparent;
  transition: border-color 0.2s linear;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:hover {
    border-color: $textColor;
  }
}

.valuationBtn:first-child {
  &:hover, &:focus {
    background-color: $white;
    color: $textColor ;
  }
}

.keywordsWrapper {
  width: 232px;
}

.keywordsInput {
  width: 232px;

   &:focus {
     box-shadow: 0 0 0 2px $activeFormElementColor !important;
   }
}

.keywordList {
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }
}

.keywordItem {
  height: max-content;
  background-color: $interactiveInputColor;
  border-radius: $borderRadius;

  span {
    max-width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.viewSwitcher {
  border-radius: $borderRadius;
  padding: 6px 10px;

  div {
    flex: 1 1 auto;
    padding: 0 8px;
  }

  div [id^=radio-] {
    display: none;
  }

  div [id^=radio-] + label {
    vertical-align: middle;
    color: $greyLabelColor;
  }

  div [id^=radio-] + label svg {
    circle {
      stroke: $greyLabelColor;
    }
    path {
      stroke: $greyLabelColor;
    }
  }

  div [id^=radio-]:checked + label {
    color: $textColor;
  }

  div [id^=radio-]:checked + label svg {
    circle {
      stroke: $textColor;
    }
    path {
      stroke: $textColor;
    }
  }
}
