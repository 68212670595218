@import 'src/style/variables';

.personItem {
  user-select: text;

  &:nth-child(odd) {
    padding-right: 16px;
  }
  &:nth-child(even) {
    padding-left: 16px;
  }
}

//.avatar {
//  width: 56px;
//  height: 56px;
//  background-color: #F5FAFE;
//} w-14 h-14 bg-light-500

//.personRole {
//  font-size: 12px;
//  color: $greyLabelColor;
//} text-xs text-grey-300

//.detailItem {
//  white-space: nowrap;
//  text-overflow: ellipsis;
//  page-break-inside: avoid;
//  break-inside: avoid;
//} whitespace-nowrap text-ellipsis break-inside-avoid-page break-inside-avoid

.personInfo {
  width: calc(50% + 74px);
}
