@import 'src/style/variables';

//.inputWrapper {
//  max-width: 553px;
//  width: 100%;
//} max-w-[553px] w-full

//.link:focus {
//  outline: none !important;
//} focus:outline-0

//.inputWrapp {
//  width: 100%;
//  color: $white !important;
//  background-color: rgba($white, 0.21) !important;
//  border-radius: $borderRadius;
//
//  .searchTagsWrapp {
//    max-width: 100%;
//    min-width: inherit;
//    display: flex;
//    align-items: center;
//
//    .locationTags {
//      width: 100%;
//      overflow: hidden;
//      text-overflow: ellipsis;
//      white-space: nowrap;
//    }
//  }
//}

//.searchInput {
//  color: $white !important;
//  background-color: rgba($white, 0.21) !important;
//
//  &:focus {
//    color: $textColor !important;
//    background-color: $white !important;
//  }
//} text-white bg-white bg-opacity-[.21] focus:text-dark focus:bg-white focus:bg-opacity-1

.searchInput {
  color: $white !important;
  //background-color: rgba($white, 0.21) !important;
  background-color: transparent !important;

  &:focus {
    color: $textColor !important;
    background-color: $white !important;
  }
}

//.searchGuestInput {
//  color: $white !important;
//  background-color: rgba($white, 0.21) !important;
//
//  &:focus {
//    color: $textColor !important;
//    background-color: $white !important;
//  }
//} text-white bg-white bg-opacity-[.21] focus:text-dark focus:bg-white focus:bg-opacity-1

//.inputSearchOverlayText {
//  color: rgba($white, 0.8);
//  height: 100%;
//  transition: 0.2s linear background-color;
//
//  &:hover {
//    background-color: rgba($white, 0.21);
//  }
//} text-white text-opacity-80 h-full ease-linear duration-200 hover:bg-white hover:bg-opacity-[.21]

//.button {
//  text-transform: uppercase;
//  font-size: 12px;
//} text-xs uppercase

//.purchase:hover {
//  background-color: darken(#00C47D, 10%) !important;
//} hover:bg-green-400 hover:bg-opacity-10

//.nav {
//  background-color: $headerBgColor;
//  height: 56px;
//  max-height: 56px;
//  min-height: 56px;
//  padding-top: 1px;
//} pt-px bg-shadesBlue-300 h-14 max-h-14

.logoutIcon path {
  stroke: white;
}

.dropdown {
  font-size: 14px;
}

.dropdownTitle {
  &.activeDropdown svg path {
    stroke: $secondary;
  }

  &:hover {
    & svg path {
      stroke: $secondary;
    }
  }
}
