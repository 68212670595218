@import 'src/style/variables';

// TODO: move to global style
.textarea {
  resize: none;
  font-size: 16px;
  color: $textColor;
  border-radius: $borderRadius;
  border: none;
  outline: none;
  box-shadow: 0 0 0 2px transparent;
  background-color: #F0F4F8;
  transition: background-color 0.2s linear,  box-shadow 0.2s linear;

  &:focus {
    box-shadow: 0 0 0 2px $activeFormElementColor;
    background-color: $white !important;
  }

  &:hover {
    background-color: $interactiveInputColor;
  }

  &::placeholder {
    color: #979BA9;
  }
}

.overlay {
  background-color: rgba(199, 199, 199, 0.2);
}