//@import 'custom';
@import 'variables';
@import 'map';
@import 'datePicker';
@import 'pagination';
@import 'treeDropdown';
@import 'carousel';
@import 'progressBar';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

@font-face {
  font-family: 'Söhne-Buch';
  font-weight: 400;
  src: local('Söhne'), url(../assets/fonts/soehne-buch.woff2) format('woff2');
}

@font-face {
  font-family: 'Söhne-Kraftig';
  font-weight: 500;
  src: local('Söhne'), url(../assets/fonts/soehne-kraftig.woff2) format('woff2');
}

@font-face {
  font-family: 'Söhne-Halbfett';
  font-weight: 600;
  src: local('Söhne'), url(../assets/fonts/soehne-halbfett.woff2) format('woff2');
}

html {
  font-family: Söhne, sans-serif;
}

body {
  font-family: Söhne-Buch, serif;
  user-select: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, b {
  font-family: 'Söhne-Kraftig', serif;
}

* {
  &:focus {
    outline: 2px $activeFormElementColor solid;
  }
}

.h-full-page {
  height: calc(100vh - 56px);
}

.clip-bottom {
  clip-path: polygon(50% 20%, 0% 100%, 100% 100%);
}

.clip-top {
  clip-path: polygon(50% 80%, 0% 0%, 100% 0%);
}

.loader {
  display: inline-block;
  transform: translateZ(1px);

  & > div {
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $secondary;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
}

.loader-hidden {
  display: none !important;
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}

.z-index-5 {
  z-index: 5;
}

.z-index-10 {
  z-index: 10;
}

.z-index-20 {
  z-index: 20;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-modal {
  background-color: rgba(11, 16, 41, 0.21);
}

.new-notification {
  width: 8px;
  height: 8px;
  background-color: $badge;
  left: 8px;
  top: 50%;
  margin-top: -4px;
}

.divider {
  height: 1px;
  background-color: #EFF0F2;
}

.or-use {
  text-transform: uppercase;
  color: $textColor;
  font-size: 14px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  & b {
    position: relative;
    background-color: $white;
    z-index: 99;
  }

  & .bgDark {
    background-color: $headerBgColor;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DDE2ED;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.custom-scroll-bar {
  &::-webkit-scrollbar {
    display: block;
    width: 12px;
    background-color: $white;
  }

  &::-webkit-scrollbar-track {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
    background-color: $borderColor;
    border-radius: 56px;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: darken($borderColor, 10%);
    }
  }
}

@layer utilities {
  .sidebar-active-icon {
    stroke: #0085F3;

    path.square {
      fill: #0085F3;
    }

    path.circle {
      fill: #0085F3;
    }
  }
}
