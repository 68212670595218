@import 'src/style/variables';

.home {
  max-height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 0.2s linear;
    background-color:$borderColor;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($borderColor, 10%);
  }
}

//.intro {
//  width: 1080px;
//  margin: 0 auto 72px;
//  //w-[1080px] mb-[72px]
//  h1 {
//    font-size: 56px;
//    line-height: 64px;
//  }
//  text-5.5xl
//  p {
//    font-size: 18px;
//    color: $inactiveTextColor;
//  }
//} text-lg text-grey-500

//.searchWrapper {
//  width: 1032px;
//  margin: auto;
//  background-color: $white;
//  box-shadow: 0 10px 80px rgba(20, 44, 106, 0.15);
//  border-radius: 4px;
//} w-[1032px] m-auto bg-white rounded shadow-search-wrapper

.searchIcon path {
  fill: $inactiveTextColor;
}

//.inputSearchOverlayText {
//  color: $inactiveTextColor;
//} text-grey-500

//.introWrapper {
//  top: 15%;
//} top-[15%]