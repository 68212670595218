@import 'style/variables';

.wrapperTable {
  max-height: calc(100vh - 214px);

  &::-webkit-scrollbar {
    display: block;
    width: 12px;
    background-color: $white;
  }

  &::-webkit-scrollbar-track {
    margin-top: 36px;
    margin-bottom: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
    background-color: $borderColor;
    border-radius: 56px;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: darken($borderColor, 10%);
    }
  }
}

thead, tbody {
  z-index: 5;
}

tbody tr:first-child {
  border-top: none !important;
}

.tableHeadCell:last-child {
  & .cellHeader {
    justify-content: flex-end;
  }
}

.sortIconUp {
  transform: rotate(0deg);
}
.sortIconDown {
  transform: rotate(180deg);
}

.contactIcon {
  cursor: pointer;

  &:hover > path {
    stroke: red;
  }
}

.description {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  padding-bottom: 2px;
  user-select: text;
}

.cellWidth {
  min-width: 110px;
}

.actionButton:not(:first-child) {
  margin-left: 16px;
}

.shadow {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
  z-index: 0;
}

.lockedWrapper {
  backdrop-filter: blur(4px);
}

.lockIcon {
  width: 100%;
  max-width: 46px;
  & svg path {
    fill: $headerBgColor;
  }
}