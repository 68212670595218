@import 'src/style/variables';

.icon {
  & svg {
    transition: transform 0.2s linear;
  }
  &.active svg {
    transform: rotate(180deg);
  }
}

//.colored {
//  stroke: $secondary;
//}

.hoverColored:hover {
  & .icon svg path, & .title svg path {
    stroke: $secondary;
  }
}

.wrapper {
  //width: 216px;
  //background: #FAFAFA;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12),
  0 5px 5px -3px rgba(0, 0, 0, 0.2);
  z-index: 9;
}

.optionsWrapper {
  //width: 216px;
  //max-height: 240px;
  //overflow-y: scroll;
  //user-select: none;

  &[data-scrollable="true"] {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      transition: background-color 0.2s linear;
      background-color: $secondary;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }
}

//.content {
//  //max-width: 100%;
//  //white-space: nowrap;
//  //text-overflow: ellipsis;
//  //font-size: 14px;
//}

//.optionIcon {
//  width: 30px;
//}

//.option:hover {
//    background-color: $interactiveInputColor;
//}

.extendedWrapper {
  //width: 368px;
  //position: absolute;
  top: 23px;
}

//.placeholder {
//  //color: $inactiveTextColor;
//  //background-color: #F0F4F8;
//  //transition: background-color 0.2s linear;
//
//  &:hover {
//    background-color: $interactiveInputColor;
//  }
//}