@import 'src/style/variables';

.table {
  max-height: calc(100vh - 150px) !important;
}

.tableWithFilters {
  max-height: calc(100vh - 214px) !important;
}

.status {
  padding: 5px 7px;
  text-transform: uppercase;
  font-size: 12px;
  background: $white;
  border: 1px solid $borderColor;
  border-radius: $borderRadius;
}

//.progressBar {
//  top: -4px;
//} -top-1