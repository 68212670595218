@import 'src/style/variables';

//.fileInput {
//  width: 0;
//  height: 0;
//  z-index: -10;
//} w-0 h-0 -z-10

//.col {
//  padding: 32px;
//  border-bottom: 1px solid #DDE2ED;
//  border-right: 1px solid #DDE2ED;
//
//  &:last-child {
//    border-right: none;
//  }
//} p-8 border-b border-b-light-600 border-r border-r-light-600 last:border-r-0

//.title {
//  color: $textColor;
//  font-size: 24px;
//  margin-bottom: 40px;
//} text-dark text-2xl mb-10

.cover {
  width: 120px;
  height: 120px;
  background-color: #F5FAFE;

  & span {
    color: $white;
    font-size: 32px;
  }

  & svg {
    width: 40px;
    height: auto;
  }

  & img {
    display: block;
    max-width: 120px;
    height: auto;
    object-fit: cover;
    object-position: center;
  }
}

.name {
  font-size: 24px;
  color: $textColor;
  line-height: 1.67;
}

.email {
  color: $inactiveTextColor;
  font-size: 16px;
  line-height: 1.5;
}