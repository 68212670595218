@import 'src/style/variables';

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.custom-cluster {
  border-radius: 4px;
  font-size: 12px;
  width: max-content !important;
  height: max-content !important;

  span {
    color: $inactiveTextColor;
  }
}

.custom-circle-cluster {
  background-color: $badge;
  color: $white;
}

.small-cluster {
  width: 30px !important;
  height: 30px !important;
}

.medium-cluster {
  width: 34px !important;
  height: 34px !important;
}

.large-cluster {
  width: 38px !important;
  height: 38px !important;
}

.very-large-cluster {
  width: 42px !important;
  height: 42px !important;
}

// TODO: remove after testing
//.customMarker {
//  padding: 9px;
//  width: 80px !important;
//  height: 50px !important;
//  text-align: center;
//  font-size: 16px;
//  font-weight: bold;
//  background-image: url('../assets/icons/marker-default.svg');
//}

//.customMarker {
//  border: 1px solid $white;
//  border-radius: 50%;
//}

//.greenMarker {
//  background-color: #71b86b;
//}
//
//.blueMarker {
//  background-color: #3a86f7;
//}
//
//.orangeMarker {
//  background-color: #e98b33;
//}
//
//.blackMarker {
//  background-color: #000000;
//}

.custom-marker-max {
  background-image: url('../assets/icons/marker-max.svg');
  width: 54px !important;
  height: 29px !important;
  color: $white;
  text-align: center;
  padding: 2px;
  top: -21px;
  left: -21px;
}

.select-marker-max {
  background-image: url('../assets/icons/marker-max-blue.svg');
}

.small-marker {
  width: 12px !important;
  height: 12px !important;
}

.medium-marker {
  width: 16px !important;
  height: 16px !important;
}

.large-marker {
  width: 20px !important;
  height: 20px !important;
}

.very-large-marker {
  width: 24px !important;
  height: 24px !important;
}

.custom-label {
  font-weight: bold;
  font-size: 16px;
  background-color: transparent;
  border: transparent;
  box-shadow: none;
  z-index: 1;
}

.leaflet-marker-icon:focus {
  outline: none !important;
}

.custom-top-tooltip,
.custom-bottom-tooltip {
  max-width: 252px;
  height: 94px;
  padding: 10px 16px;
  margin-top: -12px;
  box-shadow: 0 8px 10px rgba(81, 90, 105, 0.14),
  0 3px 14px rgba(81, 90, 105, 0.12);
}

.custom-bottom-tooltip {
  margin-top: 24px;

  &.tooltip-for-marker-max {
    top: -9px;
  }

  &.tooltip-for-medium-marker {
    top: 5px;
  }

  &.tooltip-for-large-marker {
    top: 7px;
  }

  &.tooltip-for-very-large-marker {
    top: 9px;
  }
}

.custom-top-tooltip:before {
  border: 12px solid transparent;
  border-top-color: $white;
  margin-bottom: -24px;
  margin-left: -12px;
}

.custom-bottom-tooltip:before {
  transform: rotate(180deg);
  border: 12px solid transparent;
  border-top-color: $white;
  margin-top: -24px;
  margin-left: -12px;
}

.tooltip-for-small-marker {
  left: 6px;
}

.tooltip-for-medium-marker {
  left: 8px;
}

.tooltip-for-large-marker {
  left: 10px;
}

.tooltip-for-very-large-marker {
  left: 12px;
}

.tooltip-for-marker-max {
  left: 0px;
  top: -25px;
}

.tooltip-value {
  color: $textColor;
  font-size: 18px;
  line-height: 1.2;
}

.tooltip-title {
  color: $textColor;
  font-size: 14px;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-description {
  font-size: 14px;
  color: $inactiveTextColor;
  line-height: 1.2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#mapProjectInfo {
  height: 250px;
}