@import 'src/style/variables';

.projectsWrapper {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  background-color: $white;

   &::-webkit-scrollbar {
      width: 0;
   }

  @media screen and (max-width: 1550px) {
    height: calc(100vh - 238px);
  }
}

.projectsWrapperWithoutPagination {
  height: calc(100vh - 130px);
  overflow-y: scroll;
  background-color: $white;

  &::-webkit-scrollbar {
    width: 0;
  }

  @media screen and (max-width: 1550px) {
    height: calc(100vh - 170px);
  }
}

.projectsList {
  &::-webkit-scrollbar {
    display: block;
    width: 12px;
    background-color: $white;
  }

  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border: 4px solid rgba(0, 0, 0, 0);
    background-color: $borderColor;
    border-radius: 56px;
    -webkit-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: darken($borderColor, 10%);
    }
  }
}

.loader {
  display: inline-block;
  transform: translateZ(1px);

  & > div {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $secondary;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
}

@keyframes lds-circle {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(1800deg);
    animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
  }
  100% {
    transform: rotateY(3600deg);
  }
}
