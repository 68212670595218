@import 'src/style/variables';

.statisticContainer {
  top: 16px;
  right: 168px;
  z-index: 1;
  line-height: 24px;
  background-color: rgba(47, 49, 57, 0.6);
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: $borderRadius;
}