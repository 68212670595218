@import "src/style/variables";

.react-dropdown-tree-select {
  padding: 0 !important;
}

.property .dropdown {
  & .dropdown-trigger {
    width: 108px;
  }
}

.permit-types .dropdown {
  & .dropdown-trigger {
    width: 96px;
  }

  .dropdown-content {
    right: 0;
  }
}

.dropdown {
  cursor: pointer !important;
  position: relative;

  & ul {
    list-style: none;
    padding: 0 !important;
  }

  & input {
    font-family: Söhne-Kraftig, serif;

    &::placeholder {
      font-family: Söhne-Kraftig, serif;
    }
  }

  & .tag-list {
    margin-bottom: 5px;
  }

  & .tag {
    display: none;
  }
  & .tag-item {
    margin: 0;
  }

  & .root, & .no-matches {
    display: block;
    background-color: $white;
    padding: 16px 24px !important;
    box-shadow:$overlayShadow;
  }

  & .dropdown-trigger {
    border-radius: $borderRadius;
    padding: 0  !important;
    height: 32px;
    border: 2px solid $borderColor !important;
    background-color: $white;
    overflow-y: hidden !important;
    cursor: pointer;
    transition: all 0.2s linear;

    &[aria-labelledby] {
      border: 2px solid $secondary !important;
    }
  }

  & .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
  }

  & .toggle {
    font: normal normal normal 12px/1 FontAwesome;
    color: #555;
    display: inline-block;
  }


  & .toggle.collapsed::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-image: url("../assets/icons/plus.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }

  & .toggle.expanded::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background-image: url("../assets/icons/minus.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;

  }

  & .node {
    padding: 4px !important;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background-color: $white !important;

    &[aria-level="2"] {
      padding: 4px 50px !important;
    }

    &[aria-level="3"] {
      padding: 4px 80px !important;
    }

    & label {
      display: flex;
      align-items: center;
    }
  }

  .dropdown-trigger.arrow {
    position: relative;
    transition: .2s linear background-color;

    &:hover {
      background-color: $interactiveInputColor !important;
    }

    &.top {
      background-color: $secondary !important;
      border: 2px solid $secondary !important;

      & .search {
        color: $white;

        &::placeholder {
          color: $white;
        }
      }
    }

    &.top:after {
      transform: translateY(-50%) rotate(180deg);
      background-image: url("../assets/icons/dropdown-white-arrow.svg");
    }
  }

  .dropdown-trigger.arrow:after {
    content: '' !important;
    display: block;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    margin-right: 0 !important;
    width: 12px;
    height: 7px;
    background-image: url("../assets/icons/dropdown-arrow.svg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    transition: all .2s linear;
  }

  & .tag-item .search {
    border: none !important;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0 15px;
    background-color: transparent !important;
    height: 100%;
    cursor: pointer !important;
    pointer-events: none !important;

    &::placeholder {
      color: $textColor;
      transition: all .2s linear;
    }
  }

  & .dropdown-content {
    top: calc(100% + 3px);
    min-width: 100%;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    background: transparent !important;
    z-index: 999 !important;

    & ul {
      max-height: 500px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
        transition: background-color 0.2s linear;
        background-color: $secondary;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: $primary;
      }
    }
  }
}

.permit-types {
  & .infinite-scroll-component {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(12, 1fr);
    grid-column-gap: 16px;
  }

  & i {
    display: none !important;
  }
}


