@import 'src/style/variables';

.googleBtnOrigin {
  top: 1px;
  height: 50px;
}

.googleBtn:hover,
.googleBtn:focus {
  background-color: initial;
}

.googleBtnOrigin:hover ~ .customBtn {
  transition: background-color 0.2s linear;
  background-color: $interactiveInputColor !important;
}

.forgot {
  text-align: right;
  margin-top: 5px;
  font-size: 16px;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s linear;

  & b {
    font-weight: 500;
  }

  &:hover {
    border-bottom-color: $secondary;
  }
}

.input {
  padding: 12px 8px !important;
}

.preInput::placeholder {
  color: $inactiveTextColor;
  font-size: 14px;
}

.modalWrapper {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s linear;

  &.active {
    opacity: 1;
    z-index: 9999;
    background-color: rgba(11, 16, 41, 0.21);
  }
}

.closeIcon {
  top: 25px;
  right: 22px;
}

.link {
  font-size: 16px;
  font-family: Söhne-Kraftig, serif;
  border-bottom: 1px solid transparent !important;
  transition: border-bottom-color 0.2s linear;

  &:hover {
    border-bottom-color: $secondary !important;
  }
}

.label {
  color: $inactiveTextColor;
  font-size: 14px;
}

.checkboxText {
  font-size: 14px;
  color: $textColor;
}

.privacy {
  color: $secondary;
  font-family: inherit;
}

.light:hover,
.light:focus {
  background-color: lighten($headerBgColor, 10%);
}

.extendedWrapper {
  width: 536px;
  background-image: url("../../assets/img/auth/register-extended-bg-min.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  &:after {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background-color: rgba($secondary, 0.8);
  }
}

.extendedTitle {
  padding: 60px 80px 0;
  font-size: 24px;
}

.extendedList {
  padding: 0 80px 0 100px;
  list-style-image: url("../../assets/icons/tick-white.svg");
  font-size: 18px;
}