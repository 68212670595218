@import 'src/style/variables';

.select {
  transition: background-color 0.2s linear, color 0.2s linear, border 0.2s linear;
}

.icon {
  & svg {
    transition: transform 0.2s linear;
  }

  &.active svg {
    transform: rotate(180deg);

    & path {
      stroke: $white;
    }
  }

  &.activeColored {
    & svg {
      transform: rotate(0);

      & path {
        stroke: $secondary;
      }
    }
  }
}

.optionWrapper {
  top: calc(100% + 8px);
  box-shadow: 0 8px 10px rgba(81, 90, 105, 0.14),
              0 3px 14px rgba(81, 90, 105, 0.32);

  &[data-scrollable="true"] {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      transition: background-color 0.2s linear;
      background-color: $secondary;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: $primary;
    }
  }

  &::-webkit-scrollbar {
    width: 0;
  }

  &:empty {
    display: none;
  }
}

.option {
  transition: background-color 0.1s linear, color 0.1s linear, border 0.1s linear;
}

.tickIcon path {
  stroke: $secondary;
}

.resetIcon {
  max-width: 18px;
  right: 5px;

  &.resetOpened path {
    stroke: $white;
  }
}