@import '../../style/variables';

.bellBtn {
  &:hover > path {
    fill: $secondary;
  }
}

.bellIcon {
  path {
    fill: $secondary;
  }
}

.notificationOverlay {
  left: 0;
  right: 0;
}

//.listWrapper {
//  width: 448px;
//  right: 65px;
//  top: 56px;
//  border-radius: $borderRadius;
//
//  w-124 top-14 right-[65px] rounded-sm
//  h5 {
//    font-size: 18px;
//  } text-lg
//}

.body {
  max-height: 185px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: darken($borderColor, 10%);
    }
  }
}

.notification {
  border-top: 1px solid $light;

  p {
    color: $inactiveTextColor;

    span {
      font-weight: 600;
    }
  }

  h6 {
    font-size: 16px;
  }
}

.notificationDate {
  color: $inactiveTextColor;
  text-transform: capitalize;
}