@import "src/style/variables";

//.container {
//  padding: 90px 0 120px;
//  max-width: 872px;
//} max-w-[872px] pt-[90px] pb-30

//.arrow {
//  &:first-child {
//    margin-right: 64px;
//  }
//  &:last-child {
//    margin-left: 64px;
//  }
//
//  &.disabled {
//    opacity: 0.5;
//    pointer-events: none;
//  }
//} first-child:mr-16 last:ml-16 disabled:opacity-50 disabled:pointer-events-none

//.title {
//  font-size: 48px;
//  color: $textColor;
//} text-dark text-5xl

//.photo {
//  width: 104px;
//  height: 104px;
//  z-index: 40;
//} w-26 h-26 z-40

//.report {
//  padding: 104px 90px 56px;
//  box-shadow:  0 8px 10px rgba(81, 90, 105, 0.14),
//               0 3px 14px rgba(81, 90, 105, 0.12);
//  border-radius: $borderRadius;
//  margin-top: -52px;
//  z-index: 30;
//} pt-26 px-22.5 pb-14 shadow-portal rounded-sm z-30 -mt-13

//.quote {
//  font-size: 18px;
//  color: $textColor;
//  line-height: 2rem;
//  min-height: 6rem;
//} min-h-24 text-lg text-dark leading-8

//.reportTitle {
//  color: $textColor;
//  font-size: 24px;
//} text-dark text-2xl

//.position {
//  font-size: 18px;
//  color: $inactiveTextColor;
//} text-grey-500 text-lg

.dot {
  background-color: #D6DCE7;
  width: 6px;
  height: 6px;
  margin-right: 56px;
  transition: .2s linear background-color;

  &:last-child {
    margin-right: 0;
  }

  &.active {
    background-color: $secondary;
  }
}

//.shadow,
//.shadow2 {
//  width: 90%;
//  height: 16px;
//  margin: 0 auto;
//  border-bottom-left-radius: $borderRadius;
//  border-bottom-right-radius: $borderRadius;
//  box-shadow:  0 8px 10px rgba(81, 90, 105, 0.14),
//               0 3px 14px rgba(81, 90, 105, 0.12);
//  position: relative;
//  z-index: 20;
//} w-[90%] h-4 mx-auto my-0 rounded-b-sm relative z-20 shadow-portal

//.shadow2  {
//  width: 82%;
//  z-index: 10;
//} w-[82%] z-10