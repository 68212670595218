@import 'src/style/variables';

.dropdownTitle {
  transition: color 0.2s linear;
  font-size: 18px;
  font-weight: 500;
}

//.dropdownActiveTitle {
//  color: $secondary;
//} text-primary

.tab {
  font-weight: 500;
  & li {
    line-height: 36px;
  }
}

//.subHeaderButton {
//  padding: 2px 0;
//  font-size: 14px;
//  height: 32px;
//} py-0.5 px-0 h-8 text-sm

a.subHeaderButtonDone {
  text-decoration: none;
  color: $white;
  background: $secondary;
  border-radius: $borderRadius;
  font-weight: 500;

  &:focus,
  &:hover {
    color: $white;
    transition: background-color 0.2s linear;
    background-color: darken($secondary, 10%);
  }
}

.exportIcon {
  margin-top: 2px;
}

//.pendingBtn {
//  margin-top: -5px;
//} -mt-[5px]