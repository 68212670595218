.searchTagsWrapp {
  max-width: 100%;
  min-width: inherit;
  display: flex;
  align-items: center;

  .locationTags {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}