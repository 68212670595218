@import 'src/style/variables';

.dropdownActiveIcon circle {
  fill: $secondary;
}

.value {
  white-space: nowrap;
  color: $textColor;
}

.tab {
  border-bottom: 1px solid #DDE2ED;
  padding: 0 24px;
}

.tab li {
  text-transform: capitalize;
  font-weight: bold;
  font-size: 14px;
}

.table {
  max-height: calc(100vh - 260px) !important;
}

.favoriteSelect {
  height: 28px;
  padding: 2px 0 2px 0;
}

.select {
  height: 32px;
  padding: 2px 25px !important;
  max-width: 150px;
  & span {
    transform: translateX(-6px);
  }

  &.active {
    border: 2px solid $secondary;
  }
}

.searchWrapper {
  height: 28px;
  box-shadow: 0 0 0 2px #dbdfea;
  border-radius: $borderRadius;
}

.confirmModal {
  width: 380px;

  h2 {
    font-size: 30px;
    line-height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.title {
  font-size: 18px;
  color: $textColor;
  text-transform: capitalize;
}

.listTitle {
  color: $textColor;
  font-size: 14px;
}

.input::placeholder {
  color: $inactiveTextColor;
}

.create {
  font-size: 16px;
  color: $textColor;
}

.scrollable {
  height: 200px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    transition: background-color 0.2s linear;
    background-color: $secondary;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: $primary;
  }
}
