@import 'src/style/variables';

.planWrapper {
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.22);
  border-radius: $borderRadius;
}

.planIcon {
  width: 90px;
  height: 90px;
  position: relative;

  & img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-45%, -45%);
  }
}

.wrapperTable {
  max-height: 500px;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: #F7F8FA;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $borderColor;
    width: 4px;
    border-radius: 56px;
    transition: background-color 0.2s linear;
  }
}

.price {
  font-size: 16px;
  line-height: 2.5;
  color: $textColor;
  font-weight: 500;
}

.status {
  width: fit-content;
  border: 1px solid;
  border-radius: $borderRadius;
  text-transform: uppercase;
  font-size: 12px;
}

.cancelBtn {
  border-bottom: 1px solid $inactiveTextColor;
}

.confirmModal {
  width: 434px;

  h2 {
    font-size: 26px;
    line-height: 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
}
