@import 'src/style/variables';

.table {
  height: calc(100vh - 170px);
}

//.wrapper {
//  background-color: #F7F8FA;
//} bg-light-300

//.icon {
//  transform: rotate(-90deg);
//} -rotate-90

//.exportBtn {
//  height: 32px;
//  h-8
//  & img {
//    transform: translateY(-1px); -translate-y-px
//  }
//}

//.export {
//  font-size: 14px;
//  color: $textColor;
//} text-dark text-sm

//.shadow {
//  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.7);
//} shadow-default

//.tableWrapper {
//  height: fit-content;
//} h-fit

.btn {
  width: 32px;
  height: 32px;
  border: 2px solid #E5E8EF;
  border-radius: $borderRadius;
  font-size: 14px;

  &:last-child {
    width: auto;
    padding: 0 24px;
  }

  &.activeBtn {
    border-color: $secondary;
  }
}

//.back {
//  font-size: 18px;
//  color: $textColor;
//} text-dark text-lg

//.tooltip {
//  position: relative;
//  border-radius: $borderRadius;
//  box-shadow: 0px 8px 10px rgba(81, 90, 105, 0.14), 0px 3px 14px rgba(81, 90, 105, 0.12);
//
//  &:after {
//    content: '';
//    position: absolute;
//    top: -7px;
//    width: 24px;
//    height: 24px;
//    border-radius: $borderRadius;
//    transform: rotate(135deg);
//    background-color: $white;
//  }
//} shadow-overlay relative rounded-sm after:absolute after:-top-[7px] after:rounded-sm after:bg-white after:w-6 after:h-6 after:rotate-[135deg]