@import 'src/style/variables';

.wrapper {
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }
}

.tickIcon path {
  stroke: $secondary;
}
