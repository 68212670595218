@import '../../style/variables';

.matchModal {
  width: 913px;

  h3 {
    font-size: 30px;
    padding-top: 16px;
  }

  //p.sub-title {
  //  background-color: red;
  //  color: #757B8D;
  //  font-size: 16px;
  //  line-height: 20px;
  //  padding-top: 10px;
  //}
}
.listUploadTable {
  max-height: calc(100vh - 114px);
}

.listUploadWrapper {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $borderColor;
    width: 4px;
    border-radius: 56px;
  }
}
