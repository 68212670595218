$primary: #4540BB;
$secondary: #0087FF;
$success: #04B78A;
$danger: #EF2F3C;
$light: #EFF0F2;
$white: #FFFFFF;
$textColor: #2F3139;
$inactiveTextColor: #757B8D;
$greyLabelColor: #BABCC1;

$darkInactiveTextColor: #8E94BC;

$badge: #E35656;

$interactiveInputColor: #e3efff;

$borderRadius: 2px;
$borderColor: #DBDFEA;
$activeFormElementColor: #9fd2ff;

$headerBgColor: #0C175C;

$overlayShadow: 0px 8px 10px rgba(81, 90, 105, 0.14), 0px 3px 14px rgba(81, 90, 105, 0.12);

$tooltip-max-width:                 200px;
$tooltip-color:                     $white;
$tooltip-bg:                        $white;
$tooltip-border-radius:             $borderRadius;
$tooltip-opacity:                   .9;
$tooltip-margin:                    0;

$tooltip-arrow-width:               .8rem;
$tooltip-arrow-height:              .4rem;

$form-check-input-border:           2px solid $borderColor;
$form-check-input-focus-border:     $secondary;
$form-check-input-focus-box-shadow: none;
$form-check-input-checked-bg-color: $secondary;
$form-check-label-color:            $inactiveTextColor;
$form-check-label-cursor:           pointer;
