@import "../../style/variables";

.key {
  font-weight: 500;
}

//.wrapper {
//  background: rgba(11, 16, 41, 0.21);
//  z-index: 10;
//} bg-shadesBlue-400 z-10

//.body {
//  width: 656px;
//} w-[656px]

//.iconText {
//  color: $textColor;
//  text-transform: uppercase;
//  font-size: 12px;
//} text-dark uppercase text-xs

//.shareIcon {
//  transform: translateY(-3px);
//} -translate-y-[3px]

//.divider {
//  background-color: $light;
//  height: 1px;
//} bg-light-400 h-px

//.location {
//  font-size: 20px;
//  color: $textColor;
//  user-select: text;
//  white-space: nowrap;
//  text-overflow: ellipsis;
//  overflow: hidden;
//} text-xl text-dark select-text whitespace-nowrap text-ellipsis overflow-hidden

//.close {
//  transform: translateY(-2px);
//  margin-left: 29px;
//} ml-[29px] -translate-y-[2px]

//.description {
//  user-select: text;
//} select-text

//.openPermits {
//  font-size: 14px;
//  color: $inactiveTextColor;
//} text-grey-500 text-sm

.generalInfo {
  height: calc(100% - 477px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: $white;
  }

  & .lockIcon {
    width: 100%;
    max-width: 46px;
    & svg path {
      fill: $headerBgColor;
    }
  }

  //.status {
  //  padding: 2px 10px;
  //  text-transform: uppercase;
  //  font-size: 12px;
  //  background: $white;
  //  border: 1px solid $borderColor;
  //  border-radius: $borderRadius;
  //} py-0.5 px-2.5 uppercase text-xs bg-white border border-grey-200 rounded-sm

  //.bold {
  //  font-size: 18px;
  //  color: $textColor;
  //} text-dark text-lg

  //.overflowText {
  //  white-space: nowrap;
  //  max-width: 100%;
  //  overflow: hidden;
  //  text-overflow: ellipsis;
  //  color: #2F3139;
  //} whitespace-nowrap text-ellipsis overflow-hidden max-w-full text-dark

  //.light {
  //  font-size: 16px;
  //  color: $inactiveTextColor;
  //  font-weight: 400;
  //} text-grey-500 font-normal text-base

  //.permitType {
  //  color: $inactiveTextColor;
  //  font-size: 16px;
  //} text-base text-grey-500

  //.estimated {
  //  font-size: 10px;
  //  color: $inactiveTextColor;
  //  transform: translateY(3px);
  //} translate-y-[3px] text-dark text-xxs
}
