@import "src/style/variables";

.modal {
  background: rgba(11, 16, 41, 0.21);
}

.close {
  top: 25px;
  right: 25px;
}

.body {
  width: 552px;
  background-color: #F0F4F8;
  padding: 28px;
}

.title {
  font-size: 30px;
  color: $textColor;
}

.plan {
  background-color: $secondary;
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.22);
  border-radius: 2px;
  background-repeat: no-repeat;
  background-size: initial;


  &[data-plan-type] {
    background-image: url('../../../assets/icons/city.svg');
    background-position: bottom 0 right 30px;

  }

  &[data-plan-type="national"] {
    background-image: url('../../../assets/icons/national.svg');
    background-position: bottom 0 right;
  }
}

.planLabel {
  font-size: 14px;
}

.location {
  font-size: 18px;
}

.price {
  font-size: 14px;

  .accent {
    font-size: 30px;
    font-weight: 500;
  }
}