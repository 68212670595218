@import "src/style/variables";

.modal {
  background: rgba(11, 16, 41, 0.21);
}

.body {
  width: 810px;
  //max-height: 700px;
  background-color: #F0F4F8;
  padding-bottom: 36px !important;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block;
    width: 4px;
    margin-right: 4px !important;
  }

  &::-webkit-scrollbar-track {
    margin-top: 50px;
    margin-bottom: 50px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color:$borderColor;
    width: 4px;
    border-radius: 56px;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: darken($borderColor, 10%);
    }
  }
}

.title {
  color: $textColor;
  font-size: 28px;
}

.subTitle {
  color: $textColor;
  font-size: 28px;
}

.close {
  top: 25px;
  right: 25px;
}

.wrapperBlock {
  height: 225px;
  background: $white;
  box-shadow: 0 1px 8px rgba(205, 210, 223, 0.22);
  border-radius: $borderRadius;
}

.list {
  font-size: 14px;
  color: $inactiveTextColor;
  margin-left: -6px;

  li {
    font-size: 14px;

    svg path {
      stroke: $badge;
    }
  }
}

.btnWrapper {
  width: 394px;
  height: 48px;
  color: $white;
}

.titleUpgradePlan {
  font-size: 26px;
  color: $secondary;
}

.textUpgradePlan {
  font-size: 14px;
  color: $inactiveTextColor;
}

.btnPlans {
  height: 48px;
}

.question {
  width: 292px;
  font-size: 14px;
  line-height: 22px;

  p {
    color: $inactiveTextColor;
  }

  span {
    color: $badge;
    text-transform: uppercase;
  }
}

// TODO: move to global style
.textarea {
  resize: none;
  font-size: 16px;
  color: $textColor;
  border-radius: $borderRadius;
  border: none;
  outline: none;
  box-shadow: 0 0 0 2px transparent;
  background-color: #F0F4F8;
  transition: background-color 0.2s linear,  box-shadow 0.2s linear;
  padding: 0 12px;

  &:focus {
    box-shadow: 0 0 0 2px $activeFormElementColor;
    background-color: $white !important;
  }

  &:hover {
    background-color: $interactiveInputColor;
  }

  &::placeholder {
    color: #979BA9;
  }
}
