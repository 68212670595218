@import 'src/style/variables';

.table {
  height: calc(100vh - 200px) !important;
}

.tableWithoutSubscription {
  height: calc(100vh - 160px) !important;
}

//.location {
//  font-size: 20px;
//  color: $textColor;
//  user-select: text;
//  display: block;
//  overflow: hidden;
//  text-overflow: ellipsis;
//  white-space: nowrap;
//}

.loader {
  display: inline-block;
  transform: translateZ(1px);

  & > div {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $secondary;
    animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
}
